import React from "react";
import ReactWOW from "react-wow";
import { useTranslation } from "react-i18next";
import notFoundImg from "../../assets/images/error.png";

const P404 = () => {
  const { t } = useTranslation();

  return (
    <div className="page-404 page-404-introduction">
      <div className="py-5 container d-flex flex-column justify-content-center align-items-center">
        <ReactWOW delay=".1s" animation="fadeInRight">
          <h1 className="mb-4 error-404-title">{t("errors.err9")}</h1>
        </ReactWOW>

        <ReactWOW delay=".1s" animation="fadeInLeft">
          <img src={notFoundImg} alt="404-error" />
        </ReactWOW>
        <ReactWOW delay=".1s" animation="fadeInRight">
          <p className="mt-2">
          {t("errors.err10")}
          </p>
        </ReactWOW>
      </div>
    </div>
  );
};

export default P404;
