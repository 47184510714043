import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import ContactInfo from "../components/Contact/ContactInfo";
import ContactForm from "../components/Contact/ContactForm";
import { useTranslation } from "react-i18next";


const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
        <Helmet>
        <title>{t("metatag.title.contact_us")}</title>
        <link rel="shortcut icon" type="image/png" href= {`${process.env.GATSBY_APP_OG_URL}/favicon.ico`} />
        <meta charset="utf-8" />
        <meta name="robots" content= {`${process.env.GATSBY_APP_BLOCK_SPIDER === "true" ? "noindex" : "all"}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={t("metatag.description.contact_us")} />
        <meta name="keywords" content={t("metatag.keywords.contact_us")} />
        <meta
          name="og:title"
          property="og:title"
          content={t("metatag.title.contact_us")}
        />
        <meta
          name="og:description"
          property="og:description"
          content={t("metatag.description.contact_us")}
        />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:locale:alternate" content="en_BR" />
        <meta property="og:image" content={process.env.GATSBY_APP_OG_IMG}  />
        <meta
          property="og:url"
          content={`${process.env.GATSBY_APP_OG_URL}/contact`}
        />
      </Helmet>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle={t("menu.contact_us")}
          homePageText="Home"
          homePageUrl="/"
          activePageText={t("menu.contact_us")}
        />
        <ContactInfo />
        <ContactForm />
        <Footer />
      </Layout>
    </>
  );
};

export default Contact;
