import React from "react";
import ServiceSidebar from "./ServiceSidebar";
import project2 from "../../assets/images/projects/project2.jpg";
import { useTranslation } from "react-i18next";

const ServiceDetailsContent = () => {
  const { t } = useTranslation();

  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

            <div className="services-details-desc">
              <h3>{t("h3_titles.t9")}</h3>
              <p>{t("p.p16")}</p>

              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <div className="image">
                    <img src={project2} alt="about" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="content">
                    <h3>Top Skills</h3>
                    <ul>
                      <li>CTO as a Service</li>
                      <li>Cloud Solution Architect (GCP / AWS)</li>
                      <li>System Integration (WSO2 Platform)</li>
                      <li>Web Application (Angular / React)</li>
                      <li>Mobile App (Native / PWA)</li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>{t("p.p19")}</p>
              <p>{t("p.p20")}</p>

              <div className="row align-items-center">
              
              <div className="col-lg-12 col-md-12">
                <div className="content">
                <h3>FPGA DEVELOPMENT</h3>
                  <p>{t("p.p21")}</p>
                  <ul>
                    <li>FPGA</li>
                    <li>vhdl programming</li>
                    <li>
                      Firmware development and test Interface driver development
                      in C
                    </li>
                  </ul>
                </div>
                </div>
              </div>

              <h3>{t("h3_titles.t11")}</h3>
              <p>{t("p.p22")}</p>

              <div className="row">
                <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-factory"></i>
                    </div>
                    Enterprise
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-rocket"></i>
                    </div>
                    Startups
                  </div>
                </div>
              </div>

              <h3>{t("h3_titles.t10")}</h3>
              <ul className="technologies-features">
                <li>
                  <span> Angular (JS / Typescript)</span>
                </li>
                <li>
                  <span> React (JS / Typescript)</span>
                </li>
                <li>
                  <span>Flutter (Dart)</span>
                </li>
                <li>
                  <span>Android (Kotlin / Java)</span>
                </li>
                <li>
                  <span>Spring / Spring Boot</span>
                </li>
                <li>
                  <span>Xamarin (C#)</span>
                </li>
                <li>
                  <span>Django (Python)</span>
                </li>
                <li>
                  <span>Laravel (PHP)</span>
                </li>
                <li>
                  <span>Cloud Solution Architect</span>
                </li>
                <li>
                  <span>Cloud Migration</span>
                </li>
                <li>
                  <span>Cloud Native / Serverless</span>
                </li>
                <li>
                  <span>Firebase (GCP)</span>
                </li>
                <li>
                  <span>AWS Amplify</span>
                </li>
                <li>
                  <span>Compute Engine (GCP)</span>
                </li>
                <li>
                  <span>Google Kubernetes Engine (GKE)</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <ServiceSidebar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetailsContent;
