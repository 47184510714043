import React from "react";
import { useTranslation } from "react-i18next";

const CaseStudiesSidebar = ({ projectObj, projectTechnologies }) => {
  const { t } = useTranslation();

  return (
    <div className="case-studies-sidebar-sticky">
      <div className="case-studies-details-info">
        <ul>
          <li>
            <div className="icon">
              <i className="bx bx-user-pin"></i>
            </div>
            <span>{t("span_titles.client")}</span>
            {projectObj?.client.name}
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-purchase-tag"></i>
            </div>
            <span>{t("span_titles.technologies")}</span>
            <div className = "d-flex flex-wrap">
            {projectTechnologies?.map((technology, i) =>
              i < projectTechnologies.length - 1
                ? <div className = "technology-span" key = {i}>{`${technology}, `}</div>
                : <div className = "technology-span" key = {i}>{` ${technology}`}</div>
            )}
            </div>
          </li>
          {projectObj?.client.url && 
          <li>
            <div className="icon">
              <i className="bx bx-globe"></i>
            </div>
            <span>{t("span_titles.website")}</span>
      
              <a
                href={projectObj?.client.url.address}
                target="_blank"
                rel="noreferrer"
              >
                {projectObj?.client.url.title}
              </a>
            
          </li>
}
        </ul>
      </div>
    </div>
  );
};

export default CaseStudiesSidebar;
