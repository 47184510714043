import React from "react";
import { useTranslation } from "react-i18next";
import aboutImage from "../../assets/images/about/about-img5.png";
import starIcon from "../../assets/images/star-icon.png";
import icon4 from "../../assets/images/icons/icon4.png";
import icon5 from "../../assets/images/icons/icon5.png";
import icon6 from "../../assets/images/icons/icon6.png";
import icon7 from "../../assets/images/icons/icon7.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";



const AboutUsContent = () => {

  const { t } = useTranslation();


  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={aboutImage} alt="banner" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={starIcon} alt="banner" />
                       {t("span_titles.about_us")}
                </span>
                <h2>{t("h2_titles.t14")}</h2>
                <p>
                {t("p.p7")}
                </p>

                <ul className="features-list">
                  <li>
                    <img src={icon4} alt="banner" />
                    <h5>{t("h5_titles.t1")}</h5>
                  </li>
                  <li>
                    <img src={icon5} alt="banner" />
                    <h5>{t("h5_titles.t2")}</h5>
                  </li>
                  <li>
                    <img src={icon6} alt="banner" />
                    <h5>{t("h5_titles.t3")}</h5>
                  </li>
                  <li>
                    <img src={icon7} alt="banner" />
                    <h5>{t("h5_titles.t4")}</h5>
                  </li>
                </ul>
                <p>{t("p.p8")}</p>

                <a href="#who-we-are" className="default-btn">
                  <i className="flaticon-right"></i>{t("a.a5")}<span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>

      <div className="container" id="who-we-are">
        <div className="about-inner-area">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-sm-12" >
              <div className="about-text" style={{marginTop: '80px'}}>
              <span className="sub-title" >
                  <img src={starIcon} alt="banner" />
                  {t("span_titles.who_we_are")}
                </span>
                <h2>  {t("h2_titles.t15")}</h2>
                <p>
                {t("p.p9")}
                </p>

                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    {t("li.li1")}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    {t("li.li2")}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> 
                    {t("li.li3")}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> 
                    {t("li.li4")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1" >
        <img src={shape1} alt="banner" />
      </div>
    </section>
  );
};

export default AboutUsContent;
