import React from "react";
import { useTranslation } from "react-i18next";

const Point123 = () => {
  const { t } = useTranslation();

  return (
    <div className="cookie-policy">
      <br />
      <br />
      <div className="container">
        <h3>{t("cookie_policy.point1.h3")}</h3>

        <p>
          {t("cookie_policy.point1.p1")}
          <strong>{t("cookie_policy.point1.p1Strong1")}</strong>
          {t("cookie_policy.point1.p1Part2")}
          <strong>{t("cookie_policy.point1.p1Strong2")}</strong>
          {t("cookie_policy.point1.p1Part3")}
        </p>
        <p>
          {t("cookie_policy.point1.p2")}
          <strong>{t("cookie_policy.point1.p2Strong1")}</strong>
          {t("cookie_policy.point1.p2Part2")}
          <strong>{t("cookie_policy.point1.p2Strong2")}</strong>
        </p>
        <p>
          {t("cookie_policy.point1.p3")}
          <strong>{t("cookie_policy.point1.p3Strong1")}</strong>
          {t("cookie_policy.point1.p3Part2")}
          <strong>{t("cookie_policy.point1.p3Strong2")}</strong>
          {t("cookie_policy.point1.p3Part3")}
          <strong>{t("cookie_policy.point1.p3Strong3")}</strong>
          {t("cookie_policy.point1.p3Part4")}
        </p>
        <p>{t("cookie_policy.point1.p4")}</p>
        <br />
        <br />
        <h3>{t("cookie_policy.point2.h3")}</h3>

        <p>{t("cookie_policy.point2.p1")}</p>
        <p>{t("cookie_policy.point2.p2")}</p>
        <p>{t("cookie_policy.point2.p3")}</p>
        <p>{t("cookie_policy.point2.p4")}</p>
        <p>{t("cookie_policy.point2.p5")}</p>
        <table>
          <tr>
            <th>{t("cookie_policy.point2.th1")}</th>
            <td>{t("cookie_policy.point2.td1")}</td>
            <td>{t("cookie_policy.point2.td2")}</td>
          </tr>
          <tr>
            <th>{t("cookie_policy.point2.th2")}</th>
            <td>
              {t("cookie_policy.point2.td3br1")}
              <br />
              {t("cookie_policy.point2.td3br2")}
              <br />
              {t("cookie_policy.point2.td3br3")}
              <br />
              {t("cookie_policy.point2.td3br4")}
              <br />
              {t("cookie_policy.point2.td3br5")}
              <br />
            </td>
            <td>
              <a
                href="https://www.google.com/analytics/learn/privacy.html?hl=it"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point2.td4a")}
              </a>
              <br /> {t("cookie_policy.point2.td4")}
            </td>
          </tr>
          <tr>
            <td>{t("cookie_policy.point2.td5")}</td>
            <td>{t("cookie_policy.point2.td6")}</td>
            <td>{t("cookie_policy.point2.td7")}</td>
          </tr>
        </table>
        <p>{t("cookie_policy.point2.p6")}</p>
        <br />
        <br />
        <h3>{t("cookie_policy.point3.h3")}</h3>

        <p>{t("cookie_policy.point3.p1")}</p>
        <p>{t("cookie_policy.point3.p2")}</p>
        <p>{t("cookie_policy.point3.p3")}</p>
        <table>
          <tr>
            <th>{t("cookie_policy.point3.th1")}</th>
            <td>{t("cookie_policy.point3.td1")}</td>
            <td>{t("cookie_policy.point3.td2")}</td>
          </tr>
          <tr>
            <th>{t("cookie_policy.point3.th2")}</th>
            <td>
              {t("cookie_policy.point3.td3")}
              <br />
              {t("cookie_policy.point3.td3br")}
            </td>
            <td>
              <a
                href="https://www.google.com/analytics/learn/privacy.html?hl=it"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point3.td4")}
              </a>
              <br />
              <a
                href="https://www.google.it/policies/privacy/partners/"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point3.td5")}
              </a>
            </td>
          </tr>
        </table>
        <p>{t("cookie_policy.point3.p4")}</p>
        <p>{t("cookie_policy.point3.p5")}</p>
      </div>
    </div>
  );
};

export default Point123;
