import React from "react";
import ReactWOW from "react-wow";
import bannerImg from "../../assets/images/banner-img1.png";
import { useTranslation } from "react-i18next";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>
                  {t("h2_titles.t1")}
                  <span>{t("h2_titles.t1_orange")}</span>
                </h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>
                  {t("p.p1")}
                  <span></span>
                </p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <AnchorLink to="/#our-solutions" className="default-btn">
                    <i className="flaticon-right"></i>
                    {t("cta.learn_more")}
                  </AnchorLink>
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image">
                <img src={bannerImg} alt="banner" />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
