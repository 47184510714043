import { atom } from 'recoil'


export const projectState = atom({
    key: 'projectState',
    default: {
        projectObj: null,
        projectTechnologies: [],
        loading: false,
        error: "",
        empty: false,
      },
})