import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import AboutUsContent from "../components/AboutUs/AboutUsContent";
import WhyChooseUs from "../components/AboutUs/WhyChooseUs";
// import RecentProjects from "../components/Index/RecentProjects";
import { useTranslation } from "react-i18next";


const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("metatag.title.about_us")}</title>
        <link rel="shortcut icon" type="image/png" href= {`${process.env.GATSBY_APP_OG_URL}/favicon.ico`} />
        <meta charset="utf-8" />
        <meta name="robots" content= {`${process.env.GATSBY_APP_BLOCK_SPIDER === "true" ? "noindex" : "all"}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={t("metatag.description.about_us")} />
        <meta name="keywords" content={t("metatag.keywords.about_us")} />
        <meta
          name="og:title"
          property="og:title"
          content={t("metatag.title.about_us")}
        />
        <meta
          name="og:description"
          property="og:description"
          content={t("metatag.description.about_us")}
        />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:locale:alternate" content="en_BR" />
        <meta property="og:image" content={process.env.GATSBY_APP_OG_IMG} />
        <meta
          property="og:url"
          content={`${process.env.GATSBY_APP_OG_URL}/about-us`}
        />
      </Helmet>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle={t("menu.aboutus")}
          homePageText="Home"
          homePageUrl="/"
          activePageText={t("menu.aboutus")}
        />
        <AboutUsContent />
        <WhyChooseUs />
        {/* <RecentProjects /> */}
        <Footer />
      </Layout>
    </>
  );
};

export default AboutUs;
