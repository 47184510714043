import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import howItWork from "../../assets/images/how-its-work.png";
import { t } from "i18next";

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={starIcon} alt="banner" />
                {t("span_titles.what_we_do")}
              </span>
              <h2>{t("span_titles.what_we_do")}</h2>
              <p>{t("p.p10")}</p>
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>{t("h3_titles.t5")}</h3>
                  <p>
                 {t("p.p11")}
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>{t("h3_titles.t6")}</h3>
                  <p>
                   {t("p.p12")}
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>{t("h3_titles.t7")}</h3>
                  <p>
                   {t("p.p13")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={howItWork} alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </section>    
  );
};

export default WhyChooseUs;
