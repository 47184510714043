import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import Banner from "../components/Index/Banner";
import WhyChooseUs from "../components/Index/WhyChooseUs";
import OurServices from "../components/Index/OurServices";
// import RecentProjects from "../components/Index/RecentProjects";
import ProjectStartArea from "../components/Index/ProjectStartArea";
import { t } from "i18next";
// import Firebase from "../utils/Firebase"

const Home = () => {
  return (
    <>
      <Helmet>
        <title>FIVEBITS srl - Home</title>
        <link rel="shortcut icon" type="image/png" href= {`${process.env.GATSBY_APP_OG_URL}/favicon.ico`} />
        <meta charset="utf-8" />
        <meta name="robots" content= {`${process.env.GATSBY_APP_BLOCK_SPIDER === "true" ? "noindex" : "all"}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={t("metatag.description.home")} />
        <meta name="keywords" content={t("metatag.keywords.home")} />
        <meta
          name="og:title"
          property="og:title"
          content="FIVEBITS srl - Home"
        />
        <meta
          name="og:description"
          property="og:description"
          content={t("metatag.description.home")}
        />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:locale:alternate" content="en_BR" />
        <meta property="og:image" content={process.env.GATSBY_APP_OG_IMG} />
        <meta
          property="og:url"
          content={process.env.GATSBY_APP_OG_URL}
        />
      </Helmet>
      <Layout>
        {/* <Firebase/> */}
        <Navbar />
        <Banner />
        <WhyChooseUs />
        <OurServices />
        {/* <RecentProjects /> */}
        <ProjectStartArea />
        <Footer />
      </Layout>
    </>
  );
};

export default Home;
