import React, {useState, useEffect, useCallback} from "react";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import { db } from "../../utils/firebaseConfig";


const RelatedProjects = ({projectSlug}) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState({list : [], loading: false});

  const queryProjects = useCallback( async () => {
   setProjects(p => ({...p, loading: true}))

    try {
      let projectsArr = [];
      const projectsRef = collection(db, "projects");
      const q = query(projectsRef, where("slug", "!=", projectSlug), orderBy("slug"));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => { 
          projectsArr.push(doc.data())
        });
        setProjects(p => ({...p, list: projectsArr, loading: false}))
      } else {
        setProjects(p => ({...p, loading: false}))

      }
    } catch (error) {
      console.log(error)
      setProjects(p => ({...p, list: [], loading: false}))
    }
  }, [projectSlug]);

  useEffect(() => {
    queryProjects();
  }, [queryProjects]);



  /*RENDER THE PROJECTS' CARD*/
  function printList() {

    return projects.list?.map(
      (project) =>

          <div key = {project.id} className = "px-1 p-sm-0 m-0">
            <Link
              to= {`/case-studies-details/${project.slug}`}
            >
              <div className="single-services-box ">
                <div className="icon">
                  <img src={project.img[1]} alt="about" />
                </div>
                <h3>{project.title}</h3>
                <p>{project.description.p1}</p>

                <div
                  // to="/case-studies-details"
                  className="read-more-btn"
                >
                  {t("a.a1")} <i className="flaticon-right"></i>
                </div>
              </div>
            </Link>
          </div>
      
    );
  }

  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
          
    
             <div className="container">
             <div className="section-title">
               <h2>{t("h2_titles.t13")}</h2>
             </div>
          {projects.list.length > 0 && 
           <div className="row recent-projects-grid">{printList()}</div>
           }

             
             
           </div>
      
 
    </section>
  );
};

export default RelatedProjects;
