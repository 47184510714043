import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import CookiePolicy1 from "../components/CookiePolicy/CookiePolicy";
import Point123 from "../components/CookiePolicy/Point1-2-3";
import Point4567 from "../components/CookiePolicy/Point4-5-6-7";

const CookiePolicy = () => {
  return (
    <>
      <Helmet>
        <title>FIVEBITS srl - Cookie Policy</title>
        <link
          rel="shortcut icon"
          type="image/png"
          href={`${process.env.GATSBY_APP_OG_URL}/favicon.ico`}
        />
        <meta charset="utf-8" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Navbar />
        <CookiePolicy1 />
        <Point123 />
        <Point4567 />
        <Footer />
      </Layout>
    </>
  );
};

export default CookiePolicy;
