import React from "react";
import { useTranslation } from "react-i18next";


const CookiePolicy = () => {
  const { t } = useTranslation();

  return (
    <div className=" cookie-policy cookie-policy-introduction">
      <div className="container">
        <h1>{t("cookie_policy.intro.h1")}</h1>
        <br/>
        <p>
          {t("cookie_policy.intro.p1")}
          <strong>{t("cookie_policy.intro.p1Strong1")}</strong>
          {t("cookie_policy.intro.p1Between")}
          <strong>{t("cookie_policy.intro.p1Strong2")}</strong>
          {t("cookie_policy.intro.p1End")}
        </p>
        <p>
          {t("cookie_policy.intro.p2")}
          <strong>{t("cookie_policy.intro.p2Strong1")}</strong>
          {t("cookie_policy.intro.p2Part2")}
        </p>
        <p>{t("cookie_policy.intro.p3")}</p>
        <p>
          {t("cookie_policy.intro.p4")}
          <strong>{t("cookie_policy.intro.p4Strong1")}</strong>
          {t("cookie_policy.intro.p4Part2")}
        </p>
        <p>{t("cookie_policy.intro.p5")}</p>
        <p>{t("cookie_policy.intro.p6")}</p>
        <p>{t("cookie_policy.intro.p7")}</p>
        <ul>
          <address>
            <li>{t("cookie_policy.intro.li1")}</li>
            <li>
              {t("cookie_policy.intro.li2")}
              <a href="mailto::info@5bits.it">
                {t("cookie_policy.intro.li2Email")}
              </a>
            </li>
          </address>
        </ul>
        <p>{t("cookie_policy.intro.p8")}</p>
      </div>
    </div>
  );
};

export default CookiePolicy;
