import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "react-i18next";

// TO USE THIS FILE MOVE IT IN THE PAGE'S FOLDER

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>FIVEBITS srl - Privacy Policy</title>
        <link
          rel="shortcut icon"
          type="image/png"
          href={`${process.env.GATSBY_APP_OG_URL}/favicon.ico`}
        />
        <meta charset="utf-8" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle="Privacy Policy"
          homePageText="Home"
          homePageUrl="/"
          activePageText="Privacy Policy"
        />
        <section className="privacy-policy-area py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="privacy-policy-content">
                  <p className="mb-3">{t("privacy_policy.p1")}</p>
                  <p className="mb-3">{t("privacy_policy.p2")}</p>
                  <p className="mb-3">{t("privacy_policy.p3")}</p>
                  <ul>
                    <li>{t("privacy_policy.li1")}</li>
                    <li>
                      {t("privacy_policy.li2")}{" "}
                      <a href="mailto:admin@pec.5bits.it ">
                        amministrazione@pec.5bits.it{" "}
                      </a>{" "}
                    </li>
                  </ul>
                  <p className="mb-3">{t("privacy_policy.p4")}</p>

                  <ol>
                    <li className="privacy-policy-li-big">
                      {t("privacy_policy.li3")}
                    </li>
                    <p className="mb-3">{t("privacy_policy.p5")}</p>
                    <ol>
                      <li className="privacy-policy-li-normal">
                        {t("privacy_policy.li4")}
                      </li>
                      <p className="mb-3">{t("privacy_policy.p6")}</p>
                      <li className="privacy-policy-li-normal">
                        {t("privacy_policy.li5")}
                      </li>
                      <p className="mb-3">{t("privacy_policy.p7")}</p>
                    </ol>
                    <li className="privacy-policy-li-big">
                      {t("privacy_policy.li6")}
                    </li>
                    <p className="mb-3">{t("privacy_policy.p8")}</p>
                    <li className="privacy-policy-li-big">
                      {t("privacy_policy.li7")}
                    </li>
                    <p className="mb-3">{t("privacy_policy.p9")}</p>
                    <li className="privacy-policy-li-big">
                      {t("privacy_policy.li8")}
                    </li>
                    <p className="mb-3">{t("privacy_policy.p10")}</p>
                    <li className="privacy-policy-li-big">
                      {t("privacy_policy.li9")}
                    </li>
                    <p className="mb-3">{t("privacy_policy.p11")}</p>
                    <ol style={{ "list-style-type": "lower-alpha" }}>
                      <li>{t("privacy_policy.li10")}</li>
                      <li>{t("privacy_policy.li11")}</li>
                      <li>{t("privacy_policy.li12")}</li>
                    </ol>
                    <p className="mb-3">{t("privacy_policy.p12")}</p>
                    <ol style={{ "list-style-type": "lower-alpha" }}>
                      <li>{t("privacy_policy.li13")}</li>
                      <li>{t("privacy_policy.li14")}</li>
                      <li>
                        {t("privacy_policy.li15")} (
                        <a href="http://www.garanteprivacy.it/">
                          http://www.garanteprivacy.it/
                        </a>
                        ).
                      </li>
                    </ol>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
