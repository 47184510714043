import React from "react";
import { Link } from "gatsby";
import { useFormik } from "formik";
import * as Yup from "yup";
import starIcon from "../../assets/images/star-icon.png";
import contact from "../../assets/images/contact.png";
import { useState, useEffect } from "react";
import { collection, addDoc, getFirestore } from "firebase/firestore";
import Recaptcha from "../Recaptcha/Recaptcha";
import { useRecoilState } from "recoil";
import { recaptchaStatus } from "../../utils/recoil-captcha";
import { useTranslation } from "react-i18next";
import { isBrowser } from "../../utils/isBrowserCheck";

const ContactForm = () => {
  const { t } = useTranslation();

  const [classButton, setClassButton] = useState("default-btn disabled"); //Manage the submit button style
  const [recaptcha] = useRecoilState(recaptchaStatus); //Check if recaptcha is completed correctly

  const phoneRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const db = getFirestore();

  // FORM VALIDATION

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/[a-zA-Z]/, "errors.err1")
      .required("errors.err2"),
    email: Yup.string().email("errors.err3").required("errors.err4"),
    phone_number: Yup.string()
      .matches(phoneRegex, "errors.err5")
      .required("errors.err6"),
    message: Yup.string().required("errors.err7"),
    privacy_policy: Yup.boolean().oneOf([true], "errors.err8"),
  });

  //GET CURRENT DATE dd/mm/yyyy

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; 
    let day = today.getDate();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;

    return `${day}/${month}/${year}`;
  }

  // SEND FORM DATA TO FIRESTORE -- SUBMIT

  const handleSubmit = async (values) => {
    try {
      const dataSent = await addDoc(collection(db, "contact-form"), {
        name: values.name,
        email: values.email,
        phone_number: values.phone_number,
        message: values.message,
        date: getCurrentDate()
      });

      if (dataSent) {
        if (isBrowser) window.location.reload();
        else return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //FORMIK CONFIG
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      message: "",
      privacy_policy: false,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  //DISABLE/ENABLE BUTTON LOGIC
  useEffect(
    () =>
      !recaptcha || !formik.isValid || !formik.dirty
        ? setClassButton("default-btn disabled")
        : setClassButton("default-btn"),
    [recaptcha, formik]
  );

  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="contact" />
            {t("span_titles.get_in_touch")}
          </span>
          <h2>{t("h2_titles.t17")}</h2>
          <p>{t("p.p15")}</p>
        </div>
        <div id="recaptcha" className="col-lg-12 col-md-12"></div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form id="contactForm" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={`${t("placeholders.name")}*`}
                        className={`form-control ${
                          formik.touched.name && formik.errors.name
                            ? "border-danger"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <span className="text-danger input-error">
                          {formik.errors.name === "errors.err1"
                            ? t("errors.err1")
                            : t("errors.err2")}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder={`${t("placeholders.email")}*`}
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? "border-danger"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <span className="text-danger input-error">
                          {formik.errors.email === "errors.err3"
                            ? t("errors.err3")
                            : t("errors.err4")}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        placeholder={`${t("placeholders.phone")}*`}
                        className={`form-control ${
                          formik.touched.phone_number &&
                          formik.errors.phone_number
                            ? "border-danger"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_number}
                      />
                      {formik.touched.phone_number &&
                        formik.errors.phone_number && (
                          <span className="text-danger input-error">
                            {formik.errors.phone_number === "errors.err5"
                              ? t("errors.err5")
                              : t("errors.err6")}
                          </span>
                        )}
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          id="message"
                          className={`form-control ${
                            formik.touched.message && formik.errors.message
                              ? "border-danger"
                              : ""
                          }`}
                          cols="30"
                          rows="6"
                          placeholder={`${t("placeholders.message")}*`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                        />
                        {formik.touched.message && formik.errors.message && (
                          <span className="text-danger input-error">
                            {formik.errors.message === "errors.err7"
                              ? t("errors.err7")
                              : ""}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group my-5">
                        <input
                          type="checkbox"
                          name="privacy_policy"
                          id="privacy_policy"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.privacy_policy}
                          className="form-check-input mx-2"
                        />
                        <label className="form-check-label">
                          {t("placeholders.privacy_policy")}
                          <Link to= "/privacy-policy" id="privacy-policy-link" className = "mx-1">
                            Privacy Policy
                          </Link>
                        </label>
                        {formik.touched.privacy_policy &&
                          formik.errors.privacy_policy && (
                            <span className="text-danger input-error mx-2">
                              {formik.errors.privacy_policy === "errors.err8"
                                ? t("errors.err8")
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>

                    <div className="recaptcha-submit-container">
                      <div
                      // className="col-lg-12 col-md-12"
                      >
                        <button type="submit" className={classButton}>
                          <i className="flaticon-tick"></i>
                          {t("buttons.b1")} <span></span>
                        </button>
                      </div>
                      <div id="recaptcha">
                        <Recaptcha />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
