import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";

const Spinner = ({loading}) => {
  return (

    <div className = "container-fluid d-flex justify-content-center">
    <BeatLoader  loading={loading} color = "#c2ebef" speedMultiplier={1}  size={50} />
    </div>
  )
}

export default Spinner