import React from "react";
import {useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { screenWState } from "../../utils/recoil-atoms-screenWidth";
import {isBrowser} from "../../utils/isBrowserCheck";

const ContactInfo = () => {
  const setsScreenWidth = useSetRecoilState(screenWState);

  if(isBrowser){
    window.addEventListener("resize", () => setsScreenWidth(window.screen.width));
  }



  useEffect(() => {
    if(isBrowser){
      window.screen.width < 427 && document.getElementById("iconID")
      ? (document.getElementById("iconID").style.display = "none")
      : (document.getElementById("iconID").style.display = "unset");
    }

  });

  return (
    <div className="contact-info-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 contact-info-box-main">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-map"></i>
              </div>
              <div className="icon" id="iconID">
                <i className="bx bx-map"></i>
              </div>
              {/* <h3>{t("h3_titles.t2")}</h3> */}
              {/* <p>FIVEBITS SRL</p> */}
              <p>FIVEBITS SRL</p>
              <p>VIA SAN MARTINO 23, REGGIO EMILIA 42121</p>
              <p className="e-mail-VAT">
                <span>
                  E-mail: <a href="mailto:info@5bits.it">info@5bits.it</a>
                </span>
                <span>P.IVA 02897330359 </span>
              </p>
            </div>
          </div>

          {/* <div className="col-lg-6 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <div className="icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <h3>{t("h3_titles.t3")}</h3>
              {/* <p>Mobile: <a href="tel:+44457895789">(+44) - 45789 - 5789</a></p> */}
          {/* <p>
                E-mail: <a href="mailto:info@5bits.it">info@5bits.it</a>
              </p>
              <p>P.IVA 02897330359</p>
            </div>
          </div>  */}

          {/* <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>{t("h3_titles.t4")}</h3>
                            <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: 10:30 - 22:00</p>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
