import React, { useEffect} from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent";
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects";
import { useTranslation } from "react-i18next";
import { isBrowser } from "../utils/isBrowserCheck";
import { useRecoilState } from "recoil";
import { slugParamState } from "../utils/recoil-atoms-slug-param";
import { projectState } from "../utils/recoil-atoms.project";
import i18next from "i18next";


const CaseDetails = () => {
  const { t } = useTranslation();
  const[projectSlug, setProjectSlug] = useRecoilState(slugParamState);
  const[project] = useRecoilState(projectState);

  const checkIsBrowser = isBrowser ? window.location.pathname : null

  useEffect(() => {
  if(isBrowser){
    const path = window.location.pathname;
    const pathChunks = path.split("/");
    const slug = pathChunks[pathChunks.length - 1];
    setProjectSlug(slug)
  }else return 
  }, [checkIsBrowser, setProjectSlug])

  return (
    <>
      <Helmet>
        <title>{i18next.language === "it" ? project?.projectObj?.meta_title?.ita : project?.projectObj?.meta_title?.eng}</title>
        <link rel="shortcut icon" type="image/png" href= {`${process.env.GATSBY_APP_OG_URL}/favicon.ico`} />
        <meta charset="utf-8" />
        <meta name="robots" content= {`${process.env.GATSBY_APP_BLOCK_SPIDER === "true" ? "noindex" : "all"}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content= {i18next.language === "it" ? project?.projectObj?.meta_desc?.ita : project?.projectObj?.meta_desc?.eng} />
        <meta name="keywords"  content= {i18next.language === "it" ? project?.projectObj?.meta_keywords?.ita : project?.projectObj?.meta_keywords?.eng} />
        <meta
          name="og:title"
          property="og:title"
          content= {i18next.language === "it" ? project?.projectObj?.meta_title?.ita : project?.projectObj?.meta_title?.eng}
        />
        <meta
          name="og:description"
          property="og:description"
          content= {i18next.language === "it" ? project?.projectObj?.meta_desc?.ita : project?.projectObj?.meta_desc?.eng}
        />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:locale:alternate" content="en_BR" />
        <meta property="og:image" content={process.env.GATSBY_APP_OG_IMG}  />
        <meta
          property="og:url"
          content={`${process.env.GATSBY_APP_OG_URL}/case-studies-details/${projectSlug}`}
        />
      </Helmet>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle={t("menu.case_studies_details")}
          homePageText="Home"
          homePageUrl="/"
          activePageText={t("menu.case_studies_details")}
        />
        <CaseStudiesDetailsContent projectSlug = {projectSlug}/>
        <RelatedProjects projectSlug = {projectSlug} />
        <Footer />
      </Layout>
    </>
  );
};

export default CaseDetails;
