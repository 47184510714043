import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import P404 from "../components/App/P404";

const Page404 = () => {
  return (
    <>
      <Helmet>
        <title>FIVEBITS srl - 404 Error</title>
        <link
          rel="shortcut icon"
          type="image/png"
          href={`${process.env.GATSBY_APP_OG_URL}/favicon.ico`}
        />
        <meta charset="utf-8" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Navbar />

        <P404 />
        <Footer />
      </Layout>
    </>
  );
};

export default Page404;
