import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'
import { useTranslation } from 'react-i18next';

const OurServices = () => {

    const { t } =useTranslation();

    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> {t("span_titles.services")}</span>
                                    
                                    <h2>{t("h2_titles.t3")}</h2>
                                    <p>{t("p.p6")}</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloud Solution Architect <br/>( GCP / AWS)                                            
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloud Migration <br/>(GCP / AWS)                                            
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloud Native / Serverless (GCP / AWS)                                            
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Firebase (GCP)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            AWS Amplify
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Compute Engine (GCP)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloud SQL (GCP)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Google Kubernetes Engine (GKE)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> {t("span_titles.services")}
                                    </span>
                                    
                                    <h2>{t("h2_titles.t16")}</h2>
                                    <p>{t("p.p14")} </p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Angular (Javascript/Typescript)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            React (Javascript/Typescript)
                                        </li>                                        
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Flutter (Dart)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Android Native (Kotlin / Java)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Xamarin (C#)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Spring / Spring Boot (Java)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Django (Python)
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Laravel (PHP)
                                        </li>                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;