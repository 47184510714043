import React from 'react';
import { useTranslation } from "react-i18next"; 

const ServiceSidebar = () => {
    
  const { t } = useTranslation();

    return (
        <div className="services-details-info">

            {/* <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li>
                        <Link to="#">
                           {t("a.a6")} <i className='bx bxs-file-pdf'></i>
                        </Link>
                    </li>
                </ul>
            </div> */}

            <div className="services-contact-info">
                <h3>{t("h3_titles.t8")}</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:info@5bits.it">info@5bits.it</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar