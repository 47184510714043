import React, { useEffect, useCallback } from "react";
import CaseStudiesSidebar from "./CaseStudiesSidebar";
import { useTranslation } from "react-i18next";
import { collection, getDocs, where, query } from "firebase/firestore";
import i18next from "i18next";
import { db } from "../../utils/firebaseConfig";
import Spinner from "../Spinner/Spinner";
import { useRecoilState } from "recoil";
import { projectState } from "../../utils/recoil-atoms.project";
import noDataImg from "../../assets/images/no-data.png";

const CaseStudiesDetailsContent = ({ projectSlug }) => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { projectObj, projectTechnologies, loading, error, empty } = project;

  const queryProjects = useCallback(async () => {
    setProject((project) => {
      return { ...project, loading: true, error: "", empty: false };
    });

    try {
      const projectsRef = collection(db, "projects");
      const q = query(projectsRef, where("slug", "==", projectSlug));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const technologiesArr = doc.data().technologies;
          const technologies = Object.keys(technologiesArr).map((key) => {
            return technologiesArr[key];
          });
          setProject((project) => {
            return {
              ...project,
              projectObj: doc.data(),
              projectTechnologies: technologies,
              loading: false,
              error: "",
              empty: false,
            };
          });
        });
      } else {
        setProject((project) => {
          return { ...project, loading: false, empty: true };
        });
      }
    } catch (error) {
      console.log(error)
      setProject((project) => {
        return {
          ...project,
          loading: false,
          error: error.message,
          empty: true,
        };
      });
    }
  }, [projectSlug, setProject])

  useEffect(() => {
    queryProjects();
  }, [queryProjects]);

  return (
    <section className="case-studies-details-area ptb-100">
      {loading && (
        <div className="container">
          <div className="row">
            <Spinner loading={loading} />
          </div>
        </div>
      )}

      {empty && (
        <div className="container">
          <div className="row  d-flex flex-column justify-content-center align-items-center text-center">
            <h1 className="not-found-title">{t("errors.err11")}</h1>
            <img src={noDataImg} alt="data-not-found" style={{ width: "300px" }} />
            {error && <p>{error}</p>}
            {!error && (
              <p>
                {t("errors.err12")} 
                <span className="slug-not-found"> {projectSlug} </span> {t("errors.err13")}
              </p>
            )}
          </div>
        </div>
      )}
      {projectObj && (
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="case-studies-details-image d-flex justify-content-center justify-content-md-start">
                <img src={projectObj?.img[1]} alt="about" />
              </div>
              <div className="case-studies-details-desc">
                <span className="sub-title">{projectObj?.type}</span>
                <h3>{projectObj?.title}</h3>
                <p>
                  {i18next.language === "it"
                    ? projectObj?.description?.ita?.p1
                    : projectObj?.description?.eng?.p1}
                </p>
                <p className = "mt-3">
                  {i18next.language === "it"
                    ? projectObj?.description?.ita?.p2
                    : projectObj?.description?.eng?.p2}
                </p>
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="alternate-case-studies-image ">
                      <img src={projectObj?.img[2]} alt="about" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="content">
                      <h3>{t("h3_titles.t1")}</h3>
                      <ul>
                        {projectTechnologies?.map((technology) => (
                          <li key={technology}>{technology}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <CaseStudiesSidebar
                projectObj={projectObj}
                projectTechnologies={projectTechnologies}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CaseStudiesDetailsContent;
