import React from "react";
import { Link } from "gatsby";
import starIcon from "../../assets/images/star-icon.png";
import { useTranslation } from "react-i18next";

const OurSolutions = () => {
  const { t } = useTranslation();

  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title" id="our-solutions">
            <img src={starIcon} alt="star" />
            {t("span_titles.choose_us")}
          </span>
          <h2>{t("h2_titles.t2")}</h2>
          <p>
            {t("p.p2")}
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>
                <Link to="/service-details">{t("a.a2")}</Link>
              </h3>
              <p>
                {t("p.p3")}
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>
                <Link to="/service-details">{t("a.a3")}</Link>
              </h3>

              <p>
                {t("p.p4")}
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>
                <Link to="/service-details">{t("a.a4")}</Link>
              </h3>

              <p>
                {t("p.p5")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutions;
