import React from "react";
import { useTranslation } from "react-i18next";


const Point45 = () => {
  const { t } = useTranslation();

  return (
    <div className="cookie-policy">
      <br />
      <br />
      <div className="container">
        <h3>{t("cookie_policy.point4.h3")}</h3>
        <div>
          <p>{t("cookie_policy.point4.p1")}</p>
          <p>{t("cookie_policy.point4.p2")}</p>
          <p>{t("cookie_policy.point4.p3")}</p>
          <p>{t("cookie_policy.point4.p4")}</p>
          <table>
            <tr>
              <th>{t("cookie_policy.point4.th1")}</th>
              <td>{t("cookie_policy.point4.td1")}</td>
              <td>{t("cookie_policy.point4.td2")}</td>
            </tr>
            <tr>
              <th>{t("cookie_policy.point4.th2")}</th>
              <td>{t("cookie_policy.point4.td3")}</td>
              <td>
                <a
                  href="https://www.google.com/analytics/learn/privacy.html?hl=it"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("cookie_policy.point4.td4")}
                </a>
              </td>
            </tr>
            <tr>
              <td>{t("cookie_policy.point4.td5")}</td>
              <td>
                <a
                  href="http://www.google.it/policies/privacy/partners/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("cookie_policy.point4.td5a")}
                </a>
              </td>
            </tr>
            <tr>
              <th>{t("cookie_policy.point4.th3")}</th>
              <td>
                {t("cookie_policy.point4.td6")}
                <br />
                {t("cookie_policy.point4.td6part2")}
              </td>
              <td>
                <a
                  href="https://www.linkedin.com/legal/conversion-tracking"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("cookie_policy.point4.td6a")}
                </a>
              </td>
            </tr>
          </table>
        </div>
        <br />
        <br />
        <h3>{t("cookie_policy.point5.h3")}</h3>
        <p>{t("cookie_policy.point5.p1")}</p>
        <p>
          1)
          <u>
            <strong> {t("cookie_policy.point5.u1")}</strong>
          </u>
        </p>
        <ul>
          <li>
            <strong>
              {t("cookie_policy.point5.li1")}
              <a
                href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point5.li1a")}
              </a>
            </strong>
          </li>
          <li>
            <strong>
              {t("cookie_policy.point5.li2")}
              <a
                href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point5.li2a")}
              </a>
            </strong>
          </li>
          <li>
            <strong>
              {t("cookie_policy.point5.li3")}
              <a
                href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point5.li3a")}
              </a>
            </strong>
          </li>
          <li>
            <strong>
              {t("cookie_policy.point5.li4")}
              <a
                href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point5.li4a")}
              </a>
            </strong>
          </li>
          <li>
            <strong>
              {t("cookie_policy.point5.li5")}
              <a
                href="https://help.opera.com/en/latest/web-preferences/"
                target="_blank"
                rel="noreferrer"
              >
                {t("cookie_policy.point5.li5a")}
              </a>
            </strong>
          </li>
        </ul>
        <p>{t("cookie_policy.point5.p2")}</p>
        <p></p>
        2)
        <u>
          <strong>{t("cookie_policy.point5.u2")}</strong>
        </u>
        <p>{t("cookie_policy.point5.p3")}</p>
        <p></p>
        3)
        <u>
          {" "}
          <strong>
            {t("cookie_policy.point5.u3")}{" "}
            <a href="https://www.youronlinechoices.eu/it/" target="_blank" rel="noreferrer">
              {t("cookie_policy.point5.u3a")}
            </a>
          </strong>
        </u>
        <p>{t("cookie_policy.point5.p4")}</p>
        <p>{t("cookie_policy.point5.p5")}</p>
        <br />
        <br />
        <h3>{t("cookie_policy.point6.h3")}</h3>
        <p>{t("cookie_policy.point6.p1")}</p>
        <p>{t("cookie_policy.point6.p2")}</p>
        <p>{t("cookie_policy.point6.p3")}</p>
        <br />
        <br />
        <h3>{t("cookie_policy.point7.h3")}</h3>
        <p>{t("cookie_policy.point7.p1")}</p>
        <ul>
          <li>{t("cookie_policy.point7.li1")}</li>
          <li>
            {t("cookie_policy.point7.li2")}
            <a href="mailto:info@5bits.it">{t("cookie_policy.point7.a1")}</a>
          </li>
        </ul>
        <p>{t("cookie_policy.point7.p2")}</p>
        <p>{t("cookie_policy.point7.p3")}</p>
        <p>{t("cookie_policy.point7.p4")}</p>
        <p>{t("cookie_policy.point7.p5")}</p>
        <p>{t("cookie_policy.point7.p6")}</p>
        <p>{t("cookie_policy.point7.p7")}</p>
        <ul>
          <li>{t("cookie_policy.point7.li5")}</li>
          <li>{t("cookie_policy.point7.li6")}</li>
          <li>
            {t("cookie_policy.point7.li7")}

            <ul>
              <li>{t("cookie_policy.point7.li8")}</li>
              <li>{t("cookie_policy.point7.li9")}</li>
            </ul>
          </li>
          <li>{t("cookie_policy.point7.li10")}</li>
        </ul>
        <p>{t("cookie_policy.point7.p8")}</p>
      </div>
    </div>
  );
};

export default Point45;
