import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSetRecoilState } from "recoil";
import { recaptchaStatus } from "../../utils/recoil-captcha";
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";

export default function Recaptcha() {
  const setRecaptcha = useSetRecoilState(recaptchaStatus);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  
  //When keyNumber change, the recaptcha reload itself
  //const [keyNumber, setKeyNumber] = useState(0);

  const recaptchaRef = React.createRef();

 /* useEffect(() => {
    setKeyNumber(keyNumber + 1);
  }, [i18next.language]);*/

  //if recaptcha was completed switch recaptcha global state to "true"
  function onChange() {
    //if recaptcha isn't complete recaptchaValue is empty=> false
    const recaptchaValue = recaptchaRef.current.getValue();
    recaptchaValue && setRecaptcha(true);
  }

  function onExpired() {
    setRecaptcha(false);
  }

  return (
    <>
      <ReCAPTCHA
        //if the props key change, recaptcha reload itself
        //key={keyNumber}
        ref={recaptchaRef}
        sitekey="6LdSpWIhAAAAAKN_28niIAkWhXeAyLHT-IWGByfA"
        onChange={onChange}
        onExpired={onExpired}
        // to see the size change during responsive inspection mode
        //you need to manually refresh when the breakpoint is passed
        size={isMobile ? "compact" : "normal"}
        hl={i18next.language}
      />
    </>
  );
}
